import React, { useState } from "react"
import { graphql } from "gatsby"
import Slider from "react-slick";

import Layout from "../components/layout/layout"
import Meta from "../components/addon/meta"
import Schema from "../components/addon/schema"
import Link from "../components/addon/Link"
import HospitalLocator from "../components/addon/maps/staticHospitalLocator"

// const HospitalLocator = React.lazy(() =>
//   import("../components/addon/maps/staticHospitalLocator")
// )

const Locateus = ({ data: { allNodeTemplatePages, allNodeHospitalLocation } }) => {
    // const isSSR = typeof window === "undefined"
    const [sliderRef, setSliderRef] = useState(null)
    const [activeTab, setActiveTab] = useState(1)
    const settings = {
        centerMode: true,
        dots: true,
        lazyLoad: true,
        slidesToShow: 3,
        variableWidth: true,
        autoplay: false,
        autoplaySpeed: 3000,
        centerPadding : '0px',
        responsive : false,
        responsive: [{
           breakpoint: 800,
           settings: {
              arrows: true,
              centerMode: false,
              slidesToShow: 1,
              variableWidth: false
           }
        }, {
           breakpoint: 480,
           settings: {
              arrows: true,
              centerMode: false,
              slidesToShow: 1,
              variableWidth: false
           }
        }]
    };

    const rawData = Array.isArray(allNodeTemplatePages.edges) ? allNodeTemplatePages.edges[0] : [];
    const hospitalData = Array.isArray(allNodeHospitalLocation.edges) ? allNodeHospitalLocation.edges : [];
    
    const metaTags = rawData.node?.metatag_normalized.length > 0 ? rawData?.node?.metatag_normalized : [];
    const schema = rawData?.node?.relationships?.field_seo_schema ? rawData?.node?.relationships?.field_seo_schema : []
    const canonicalUrl = rawData.node?.metatags_on_page || {}
    const textData = rawData?.node?.relationships?.components;

    const findUsTitle = textData[0]?.relationships?.components[0]?.title || ''
    const findUsDescription = textData[0]?.relationships?.components[0]?.subtitle || ''

    const rightSpotTitle = textData[1]?.relationships?.components[0]?.field_title || ''
    const rightSpotDescription = textData[1]?.relationships?.components[0]?.text?.processed || ''

    const supportTitle = textData[2]?.relationships?.components[0]?.title || ''
    const supportTitle2 = textData[2]?.relationships?.components[0]?.field_title_1 || ''
    const supportDescription = textData[2]?.relationships?.components[0]?.description?.processed || ''
    const supportBtnData = textData[2]?.relationships?.components[0] || ''
    const supportImg = textData[2]?.relationships?.components[0]?.relationships?.image?.uri?.url || '/assets/images/locate-us/additional-supports-icon.svg'
    const syncSelected = (i) => {
        sliderRef?.slickGoTo(i-1)
        setActiveTab(i)
    }

    const viewFloorDetails = (id, i) => {
        let elem = document.getElementById('tab-container') 
        sliderRef?.slickGoTo(i-1)
        setActiveTab(i)
        if(elem && window) {
            elem.scrollIntoView({behavior: "smooth", block: "start", inline: 'start'});
        }
    }

    return (
        <>
            <Layout>
                <Meta
                    files={{
                        js: [],
                        css: ["/assets/css/homepage.css","/assets/css/locate-us.css", "/assets/css/accessibility.css"],
                    }}
                    tags={{metaTags,canonicalUrl}}
                />
                <Schema schema={schema} />
                <main className="innerpage">
                    <section className="section_bg pt-first-section pb-section">
                        <div className="locateUsMap">
                            <div className="mapContent">
                                <div className="container">
                                    <h2>{findUsTitle}</h2>
                                    {/* <p>{findUsDescription}</p>*/}
                                </div>  
                            </div>  
                            {(hospitalData?.length) ?
                                // <React.Suspense fallback={<div />}>
                                    <HospitalLocator syncSelected={syncSelected} hospitalData={hospitalData} />
                                // </React.Suspense>
                            : 
                                null
                            }
                        </div>    
                        <div className="locateUsHtGallery hp_gallery overflow-hidden">
                            <div className="container">
                                <Slider ref={slider => { setSliderRef(slider) }} {...settings} className="slick-dotted slick-slider center-slide">
                                    {hospitalData?.length 
                                    ? hospitalData.map((hospital,i) => {
                                        const hospitalImg = hospital?.node?.relationships?.field_desktop_image?.uri?.url || ''
                                        const hospitalAlt = hospital?.node?.field_desktop_image?.alt || ''
                                        const hospitalName = hospital?.node?.title || ''
                                        const hospitalId = hospital?.node?.id || ''
                                        return (
                                            <div className="img_slide" key={hospitalId}>
                                                {hospitalImg &&
                                                    <figure> 
                                                        <img src={hospitalImg} alt={hospitalAlt} />
                                                    </figure>
                                                }
                                                <div className="row justify-content-between pt-4">
                                                    <div className="col-md-6">
                                                        <h2 className="text-left">{hospitalName}</h2>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="btnWrap text-right">
                                                            <a href="javascript:;" className="btn btn-primary" onClick={() => viewFloorDetails(hospitalId, i+1)} data-href={hospitalId}>VIEW FLOOR DETAILS</a>
                                                        </div>  
                                                    </div> 
                                                </div>  
                                            </div>
                                        )
                                    }) 
                                    : null}
                                </Slider>
                            </div>
                        </div>
                        <div id='tab-container' className="reachRightSpotWrap">
                            <div className="container" >
                                <div className="py-4 text-center">
                                    <h2>{rightSpotTitle}</h2>
                                    <div dangerouslySetInnerHTML={{ __html: rightSpotDescription }} />
                                </div>  
                                <div className="aboutContainer">
                                    <ul className="nav nav-tabs gallery-tabs min-space" role="tablist">
                                        {hospitalData?.length 
                                        ? hospitalData.map((hospital, index) => {
                                            const hospitalName = hospital?.node?.title || ''
                                            const hospitalId = hospital?.node?.id || ''
                                            return (
                                                <li key={hospitalId} role="presentation" onClick={() => setActiveTab(index + 1)} className="nav-item"> 
                                                    <a className={`nav-link ${activeTab === index + 1 ? 'active' : '' }`} data-toggle="tab" data-ref={hospitalId} href={`#${hospitalId}`} role="tab" aria-controls={hospitalId} aria-selected="true">{hospitalName}</a>
                                                </li>
                                            )
                                        })
                                        : null}
                                        
                                        {/* <li role="presentation" className="nav-item"> <a className="nav-link" data-toggle="tab" data-ref="block2" href="#block2" role="tab" aria-controls="block2" aria-selected="false">OPD Building (East Block)</a> </li>
                                        <li role="presentation" className="nav-item"> <a className="nav-link" data-toggle="tab" data-ref="block3" href="#block3" role="tab" aria-controls="block3" aria-selected="false">LG Tower 1- S1 Building</a> </li>
                                        <li role="presentation" className="nav-item"> <a className="nav-link" data-toggle="tab" data-ref="block4" href="#block4" role="tab" aria-controls="block4" aria-selected="false">LG Tower 2- S2 Building</a> </li> */}
                                    </ul>
                                </div>
                                <div className="tab-content mt-3">
                                    {hospitalData?.length 
                                    ? hospitalData.map((hospital, index) => {
                                        const hospitalName = hospital?.node?.title || ''
                                        const hospitalId = hospital?.node?.id || ''
                                        const hospitalFloorArr = hospital?.node?.relationships?.field_floor_details || []
                                        return (
                                            <div key={hospitalId} className={`tab-pane ${activeTab === index + 1 ? 'active' : '' }`} id={hospitalId} role="tabpanel" aria-labelledby={hospitalId}>
                                                <div className="card">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="buildingDirections">
                                                                <ul>
                                                                    {hospitalFloorArr?.length 
                                                                    ? hospitalFloorArr.map(floor => {
                                                                        return (
                                                                            <li key={floor?.id}>
                                                                                <h4>{floor?.field_title}</h4>
                                                                                <div dangerouslySetInnerHTML={{ __html: floor?.field_description?.processed }}></div>
                                                                            </li>
                                                                        )
                                                                    }) 
                                                                    : null}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }) 
                                    : null}
                                </div>
                            </div> 
                        </div> 
                        <div className="additionalSupportWrap">
                            <div className="container">
                                <div className="imageContentWrap row">
                                    <div className="col-md-7">
                                        <div className="imageSection">
                                            <span className="icon">
                                                <img src={supportImg} alt={supportTitle}/>
                                            </span>
                                            <div className="contentWrap">
                                                <h4>{supportTitle}<br /> {supportTitle2}</h4>
                                                <div dangerouslySetInnerHTML={{ __html: supportDescription }} />
                                            </div> 
                                        </div>    
                                    </div>   
                                    <div className="col-md-5">
                                        <div className="btnWrap">
                                            <a href="tel:1800002893" className="btn btn-primary-solid-white">{supportBtnData?.link?.title}</a>
                                        </div>  
                                    </div>
                                </div> 
                            </div>
                        </div> 
                    </section>  
                </main>
            </Layout>
        </>
    )
}

export const query = graphql`
query locateUS {
    allNodeTemplatePages(filter: {
        path: {
            alias: {
                regex: "/locate-us$/"
            }
        }
    }) {
        edges {
            node {
                id
                title
                path {
                    alias
                }
                relationships{
                    field_seo_schema{
                        field_seo_title
                        field_seo_schema_text_area
                    }
                }
                metatag_normalized {
                    attributes {
                        content
                        name
                        property
                    }
                }
                metatags_on_page: field_meta_tags{
                    canonical_url
                  }
                relationships {
                    components: field_component_type {
                        __typename
                            ...ParagraphSectionLU
                    }
                }
            }
        }
    }
    allNodeHospitalLocation {
        edges {
            node {
                id
                title
                body {
                    processed
                }
                field_desktop_image {
                    alt
                }
                field_location {
                    bottom
                    geo_type
                    geohash
                    lat
                    latlon
                    left
                    lon
                    right
                    top
                    value
                }
                relationships {
                    field_desktop_image {
                        uri {
                            value
                            url
                        }
                    }
                    field_floor_details {
                        id
                        field_title
                        field_description {
                            processed
                        }
                    }
                }
                field_contact_no
            }
        }
    }
}



fragment ParagraphSectionLU on paragraph__section {
    id
    name: field_name
    relationships {
        components: field_components {
            __typename
                ...ParagraphBannerLU
                ...ParagraphTitleAndDescriptionLU
                ...ParagraphImageTitleDescLinkLU
        }
    }
}



fragment ParagraphBannerLU on paragraph__banner {
    id
    title: field_title
    subtitle: field_subtitle
    bannerImage: field_banner_image {
        alt
    }
    bannerLink: field_banner_link {
        uri
        title
    }
    relationships {
        bannerImage: field_banner_image {
            id
            uri {
                value
                url
            }
        }
    }
}



fragment ParagraphTitleAndDescriptionLU on paragraph__title_and_description {
    id
    field_title
    text: field_description {
        processed
    }
    field_cta_link {
        title
        uri
    }
    field_text {
        processed
    }
}



fragment ParagraphImageTitleDescLinkLU on paragraph__image_title_desc_link {
    id
    title: field_title
    field_title_1
    description: field_description {
        processed
    }
    link: field_link {
        uri
        title
    }
    relationships {
        image: field_image {
            id
            uri {
                value
                url
            }
        }
    }
}
`
export default Locateus