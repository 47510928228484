import React, { useState } from "react"

const HospitalLocator = (props) => {

    const { hospitalData, syncSelected } = props
    const [selectedHospital, setSelectedHospital] = useState(1)

    const changeLocation = (index, data) => {
        setSelectedHospital(index+1)
        syncSelected(index+1)
    }

    return (
        <div className="locationListWrap">
            <div className="mapArea">
                <img src="/assets/images/map-helpline.jpg" className="d-block ml-auto mr-auto" alt="map image" />
            </div>
            <div className="locationList d-none d-md-block">
                <ul  className="scrollExample scrollbar-dusty-grass thin">
                    {hospitalData?.length 
                    ? hospitalData.map((hospital, i) => {
                        const hospitalName = hospital?.node?.title || ''
                        const hospitalId = hospital?.node?.id || ''
                        const hospitalDescription = hospital?.node?.body?.processed || ''
                        return (
                            <li onClick={() => changeLocation(i, hospital?.node)} className={`${selectedHospital === i+1 ? 'active' : ''}`} key={hospitalId}>
                                <p className="topText"><strong>{hospitalName}</strong></p>
                                <div dangerouslySetInnerHTML={{ __html: hospitalDescription }} />
                            </li>
                        )
                    })
                    : null}
                </ul> 
            </div>
        </div> 
    )
}

export default HospitalLocator